<template>
  <div class="d-flex">
    <dx-util-number-box v-model="newLength"
      type="default"
      label="Length" format="#0.0 in"
      class="mr-half" :step="0.1"
      :min="1"
      width="100"
    />
    <dx-util-number-box v-model="newWidth"
      type="default"
      label="Width" format="#0.0 in"
      class="mr-half" :step="0.1"
      :min="1"
      width="100"
    >
    </dx-util-number-box>
    <dx-util-number-box v-model="newHeight"
      type="default"
      label="Height" format="#0.0 in"
      class="mr-half" :step="0.1"
      :min="1"
      width="100"
    >
    </dx-util-number-box>
    <dx-util-number-box v-model="newWeight"
      type="default"
      label="Weight" format="#0.0 lb"
      class="mr-half" :step="0.1"
      width="100"
    />
    <dx-util-button text="Save" type="success" class="mt-half mr-half" @click="updateDimData" />
  </div>
</template>

<script>
import productsService from '@/http/requests/product/productsService'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newWeight: null,
      newHeight: null,
      newWidth: null,
      newLength: null,
    }
  },
  mounted() {
    this.newWeight = this.item.weight
    this.newHeight = this.item.dimHeight
    this.newWidth = this.item.dimWidth
    this.newLength = this.item.dimLength
  },
  methods: {
    async updateDimData() {
      const payload = {
        id: this.item.pshProductId,
        weight: this.newWeight,
        dimHeight: this.newHeight,
        dimWidth: this.newWidth,
        dimLength: this.newLength,
      }
      await productsService.update(payload)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
